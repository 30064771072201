<template>
  <VCard>
    <template #title>
      <div class="space-y-2 mb-4">
        <a-icon type="lock" style="font-size: 50px;" :style="titleColor"></a-icon>
        <div v-if="translations.surveyPassword.title.active"
             :style="titleColor">
          {{ translations.surveyPassword.title.value || translations.surveyPassword.title.defaultValue }}
        </div>
      </div>
    </template>

    <a-form :form="form"
            class="flex flex-grow flex-col justify-between"
            @submit="onCheckPassword">
      <a-form-item
          :validate-status="passwordError() ? 'error' : ''"
          :help="passwordError() || ''"
      >
        <a-input-password
            :placeholder="translations.surveyPassword.placeholder.active ? translations.surveyPassword.placeholder.value || translations.surveyPassword.placeholder.defaultValue : ''"
            size="large"
            class="input-password"
            v-decorator="['password', { rules: [{ required: true, message: errorText ? errorText : ' ' }] }]"
        ></a-input-password>
      </a-form-item>
      <a-form-item style="margin: 0">
        <a-button
            type="primary"
            size="large"
            html-type="submit"
            class="btn-action-primary"
            :style="[buttonBgColor, buttonTextColor]"
            :disabled="hasErrors(form.getFieldsError())"
            :loading="isLoading"
        >
          {{
            translations.surveyPassword.buttonSubmit.value || translations.surveyPassword.buttonSubmit.defaultValue
          }}
        </a-button>
      </a-form-item>
    </a-form>
  </VCard>
</template>

<script>
import hasErrors from "@/helpers/formValidate";
import {mapGetters} from 'vuex';
import {SurveyBotAccessDenied} from "@/helpers/customIcon";
import VCard from "./VCard";
import themeMixin from "../mixins/theme.mixin";

export default {
  name: "VPassword",
  props: {
    translations: {
      type: Object
    },
    errorText: {
      type: String
    }
  },
  components: {VCard},
  mixins: [themeMixin],
  data() {
    return {
      hasErrors,
      form: this.$form.createForm(this, {name: 'survey_password_form'}),
      accessIcon: SurveyBotAccessDenied
    }
  },
  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });
  },
  computed: {
    ...mapGetters(['isLoading', 'survey']),
  },
  methods: {
    // Only show error after a field is touched.
    passwordError() {
      const {getFieldError, isFieldTouched} = this.form;
      return this.errorText || (isFieldTouched('password') && getFieldError('password'));
    },
    onCheckPassword(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const {password} = values;
          this.$emit('onPassword', password);
        }
      });
    },
  }
}
</script>
