<template>
  <div id="app">
    <div class="layout flex flex-col justify-between px-6 md:px-12">
      <TheHeader/>
      <router-view></router-view>
      <TheFooter/>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter
  },
  mounted() {
    this.appHeight();
    window.addEventListener('resize', this.appHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.appHeight);
  },
  methods: {
    appHeight() {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    },
  },
}
</script>

<style lang="less">
.layout {
  height: var(--app-height);
  background-color: transparent;
}
</style>
