<template>
  <div class="absolute left-0 mt-6 w-full text-center">
    <div class="flex items-center justify-center space-x-3">
      <div class="font-light text-lg" :style="helpTextColor">
        <slot name="content"></slot>
      </div>
      <slot name="icon">
        <SwipeThumbSvg :style="helpTextColor"/>
      </slot>
    </div>
  </div>
</template>

<script>
import themeMixin from "../mixins/theme.mixin";
import {SwipeThumbSvg} from "../helpers/customIcon";

export default {
  name: "VHelpText",
  components: {SwipeThumbSvg},
  mixins: [themeMixin],
}
</script>

<style scoped>

</style>
