import _ from "lodash";

export function evaluateCondition(trigger, value, conditionValue) {
  switch (trigger) {
    case "=":
      return +value === +conditionValue;
    case "≠":
      return +value !== +conditionValue;
    case ">":
      return +value > +conditionValue;
    case "<":
      return +value < +conditionValue;
    case "≥":
      return +value >= +conditionValue;
    case "≤":
      return +value <= +conditionValue;
    case "is empty":
      return _.isEmpty(value);
    case "is not empty":
      return !_.isEmpty(value);
    case "is":
      return value?.toLowerCase().trim() === conditionValue?.toLowerCase().trim();
    case "is not":
      return value?.toLowerCase().trim() !== conditionValue?.toLowerCase().trim();
    case "contains":
      if (_.isString(value)) {
        value = value?.toLowerCase().trim()
        conditionValue = conditionValue?.toLowerCase().trim()
      }
      return _.includes(value, conditionValue);
    case "does not contain":
      if (_.isString(value)) {
        value = value?.toLowerCase().trim()
        conditionValue = conditionValue?.toLowerCase().trim()
      }
      return !_.includes(value, conditionValue);
    case "begins with":
      return _.startsWith(value?.toLowerCase().trim(), conditionValue?.toLowerCase().trim());
    case "ends with":
      return _.endsWith(value?.toLowerCase().trim(), conditionValue?.toLowerCase().trim());
    default:
      return false;
  }
}

export function calculate(currentValue, type, operation, value) {
  if (type === "number") {
    switch (operation) {
      case "+":
        return +currentValue + +value;
      case "-":
        return +currentValue - +value;
      case "*":
        return +currentValue * +value;
      case "/":
        return +currentValue / +value;
      case "=":
        return +value;
      default:
        console.error("Invalid operation for calculation");
        return +currentValue;
    }
  } else {
    switch (operation) {
      case "+":
        return currentValue.toString() + value.toString();
      case "=":
        return value.toString();
      default:
        console.error("Invalid operation for calculation");
        return currentValue.toString();
    }
  }
}
