<template>
  <div>
    <!--Title-->
    <VTitle :title="question.text" class="mb-6 md:mb-7 space-y-3">
      <template #extra>
        <div v-if="requiredAnswers > 1" class="text-base font-normal" :style="helpTextColor">
          ({{
            survey.translations.questions.multiple.requiredDesc.value || survey.translations.questions.multiple.requiredDesc.defaultValue
          }}: {{ requiredAnswers }})
        </div>
      </template>
    </VTitle>

    <!--Choices-->
    <div class="space-y-4 sm:space-y-5">
      <label
          v-for="(choice, index) in choices"
          :key="choice.value"
          class="multiple-choice__item flex items-center w-full cursor-pointer bg-white pl-5 py-4 pr-9 text-center transition-colors duration-300"
          :class="{active: selectedValues.includes(choice.value)}"
          :style="selectedValues.includes(choice.value) ? `background-color: ${accentColor.color}` : ''"
      >
        <input
            :type="isSingleSelection ? 'radio' : 'checkbox'"
            class="hidden"
            :value="choice.value"
            v-model="selectedValues"
            @click="onSelect"
        >
        <span
            class="mr-2 flex-none font-light text-lg"
            :style="selectedValues.includes(choice.value) ? 'color: white;' : accentColor"
        >
						{{ index + 1 | numToLetter }}.
					</span>
        <span class="flex-grow text-xl leading-2"
              :style="selectedValues.includes(choice.value) ? 'color: white;' : textColor">{{ choice.text }}</span>
      </label>
    </div>

    <!--Button-->
    <div
        v-if="isNextAvailable"
        class="flex items-start justify-center mt-4 sm:mt-5 pointer-events-none"
        style="height: 60px;"
    >
      <div ref="nextButton"
           class="flex items-start justify-center"
           :class="{'fixed left-0 w-full': isNextBtnFixed}"
           :style="isNextBtnFixed ? 'bottom: 80px;' : ''"
      >
        <a-button
            type="primary"
            size="large"
            class="btn-action-primary pointer-events-auto"
            :disabled="this.selectedValues.length < this.requiredAnswers"
            :style="[buttonBgColor, buttonTextColor]"
            @click="finishSelect"
        >
          {{
            survey.translations.questions.multiple.buttonSubmit.value || survey.translations.questions.multiple.buttonSubmit.defaultValue
          }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import questionMixin from "@/mixins/question";
import {mapGetters} from "vuex";
import themeMixin from "../mixins/theme.mixin";
import VTitle from "./VTitleNoCard";

export default {
  name: "VMultiple",
  components: {VTitle},
  mixins: [questionMixin, themeMixin],
  filters: {
    numToLetter: function (value) {
      let s = '', t;
      while (value > 0) {
        t = (value - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        value = (value - t) / 26 | 0;
      }
      return s || value;
    }
  },
  data() {
    return {
      selectedValues: [],
      isNextBtnFixed: false,
    }
  },
  watch: {
    selectedValues: {
      handler: function (value) {
        if (!value.length || !this.isNextAvailable || this.selectedValues.length < this.requiredAnswers) {
          this.isNextBtnFixed = false
          return
        }

        this.$nextTick(() => {
          const nextBtn = this.$refs.nextButton
          const buttonPos = nextBtn.offsetTop
          const windowHeight = document.body.offsetHeight

          if (buttonPos > windowHeight) {
            this.isNextBtnFixed = true
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters(["survey"]),
    requiredAnswers: function () {
      return this.question.options.requiredAnswers;
    },
    isSingleSelection: function () {
      return this.question.options.isSingleSelection;
    },
    choices: function () {
      let choices = this.question.choices;
      return choices.map(function (choice) {
        choice.value = choice._id;
        return choice;
      });
    },
    isNextAvailable: function () {
      return this.isSingleSelection !== true;
    },
  },
  methods: {
    finishSelect() {
      setTimeout(() => this.onAnswer(this.selectedValues), process.env.VUE_APP_NEXT_QUESTION_DELAY);
    },

    onSelect() {
      if (this.isSingleSelection === true) {
        this.finishSelect();
      }
    }
  }
}
</script>

<style lang="less" scoped>
.multiple-choice {
  &__item {
    max-width: 376px;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    position: relative;
    transition: transform ease-in-out .15s;

    &:not(.active):hover {
      transform: scale(1.05);
    }
  }
}
</style>
