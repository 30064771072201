const ApiService = {
	setHeaders() {
		return {
			'Content-Type': 'application/json',
		}
	},

	async get(resource) {
		return (await fetch(`${resource}`, {
			headers: this.setHeaders()
		})).json();
	},

	async post(resource, params = {}) {
		return (await fetch(`${resource}`, {
			headers: this.setHeaders(),
			method: 'POST',
			body: JSON.stringify(params),
		})).json();
	},
};

export default ApiService;
