<template>
  <div class="card-layout flex flex-col justify-between text-center overflow-hidden"
       :class="cardAttrs.classes"
       :style="cardAttrs.styles">

    <!--Title-->
    <div v-if="$slots.title"
         class="flex items-end justify-center mb-4 text-2xl font-semibold leading-1"
         style="min-height: 80px;">
      <slot name="title"></slot>
    </div>

    <!--Content-->
    <div class="flex flex-col flex-grow text-xl leading-2">
      <slot></slot>
    </div>

    <!--Button-->
    <div v-if="$slots.button" class="mt-8">
      <slot name="button"></slot>
    </div>

  </div>
</template>

<script>
import {parseImgUrl} from "../helpers/fns";

export default {
  name: "VCard",
  props: {
    noPadding: Boolean,
    bgImage: String,
  },
  computed: {
    cardAttrs: function () {
      let classes = ""
      let styles = ""

      classes += this.noPadding ? "" : "py-8 px-7 "
      classes += this.bgImage ? "bg-cover bg-center bg-no-repeat " : "bg-white "

      styles += this.bgImage ? `background-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, .75), rgb(255, 255, 255)), url(${parseImgUrl(this.bgImage)})` : ''

      return {
        classes,
        styles,
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card-layout {
  width: 100%;
  min-height: 320px;
  box-shadow: 5px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

@media (min-width: 375px) {
  .card-layout {
    width: 320px;
    min-height: 320px;
  }
}

@media (min-width: 400px) {
  .card-layout {
    width: 350px;
    min-height: 350px;
  }
}

@media @v-tablet-break-point {
  .card-layout {
    width: 376px;
    min-height: 376px;
  }
}
</style>
