export const questionMixin = {
	props: {
		question: Object
	},
	methods: {
		onAnswer(value) {
			this.$emit('onAnswer', {question_id: this.question._id, value});
		}
	}
};

export const questionEventListenerMixin = {
	mounted() {
		document.addEventListener('keyup', this.onKeyUp, false);
		document.addEventListener('keydown', this.onKeyDown, false);
	},
	destroyed() {
		document.removeEventListener('keyup', this.onKeyUp, false);
		document.removeEventListener('keydown', this.onKeyDown, false);
	},
};

export default questionMixin;
