<template>
  <div>
    <!--Title-->
    <VTitleNoCard :title="question.text" class="mb-8 md:mb-12"/>

    <!--Choices-->
    <div class="flex">
      <div class="m-auto">


        <div
            class="items-center justify-center m-auto sm:m-0"
            :class="scoreScale > maxBreakScore ? 'flex flex-col sm:flex-row flex-col-reverse' : 'block sm:flex'"
        >
			<span v-if="showMinLabel"
            class="sm:hidden text-base sm:mr-4"
            :style="helpTextColor"
            :class="scoreScale > maxBreakScore ? 'block mt-3 sm:mt-0' : 'hidden'"
      >
				{{ minLabel }}
			</span>
          <div class="flex sm:flex-row sm:space-x-4"
               :class="{
             'flex-col flex-col-reverse': scoreScale > maxBreakScore,
             'flex-row space-x-2': scoreScale <= maxBreakScore,
             }"
          >
            <a-button
                v-for="choice in arScoreScale"
                :key="choice"
                type="default"
                shape="circle"
                size="large"
                class="btn-nps flex-none transform hover:-translate-y-1 transition-all ease-out duration-300"
                :class="{'active': activeNum === choice, 'btn-nps-col mb-5 first:mb-0 sm:mb-0': scoreScale > maxBreakScore}"
                :style="{...textColor, backgroundColor: activeNum === choice ? accentColor.color : 'white'}"
                @click="onSelect(choice)"
            >
              {{ choice }}
            </a-button>
          </div>
          <span v-if="showMaxLabel"
                class="sm:hidden text-base sm:ml-4"
                :style="helpTextColor"
                :class="scoreScale > maxBreakScore ? 'block mb-3 sm:mb-0' : 'hidden'"
          >
				{{ maxLabel }}
			</span>
        </div>


        <div class="justify-between w-full mt-4"
             :class="scoreScale > maxBreakScore ? 'hidden sm:flex' : 'flex'"
        >
          <div><span v-if="showMinLabel"
                     class="text-base"
                     :style="helpTextColor">{{ minLabel }}</span>
          </div>
          <div><span v-if="showMaxLabel"
                     class="text-base"
                     :style="helpTextColor">{{ maxLabel }}</span>
          </div>
        </div>

        <VHelpText v-if="survey.translations.questions.nps.keyDesc.active" class="hidden sm:block mt-5">
          <template #content>
            <div class="font-light text-base" :style="helpTextColor">
              {{
                survey.translations.questions.nps.keyDesc.value || survey.translations.questions.nps.keyDesc.defaultValue
              }}
            </div>
          </template>
          <template #icon>
            <div
                class="flex items-center justify-center border border-solid leading-none"
                :style="{...helpTextColor, borderColor: helpTextColor.color}"
                style="font-size: 12px; border-radius: 2px; width: 32px; height: 24px;"
            >
              1
            </div>
          </template>
        </VHelpText>

      </div>
    </div>
  </div>
</template>

<script>
import {questionMixin, questionEventListenerMixin} from "@/mixins/question";
import {range} from 'lodash';
import {mapGetters} from "vuex";
import themeMixin from "../mixins/theme.mixin";
import VHelpText from "./VHelpText";
import VTitleNoCard from "./VTitleNoCard";

export default {
  name: "VNps",
  props: {isVisible: Boolean},
  components: {VHelpText, VTitleNoCard},
  mixins: [questionMixin, questionEventListenerMixin, themeMixin],
  data() {
    return {
      activeNum: null,
      maxBreakScore: 4,
      minBreakScore: 3,
    }
  },
  computed: {
    ...mapGetters(["survey"]),
    scoreScale: function () {
      return this.question.options.scoreScale;
    },
    arScoreScale: function () {
      return range(0, this.question.options.scoreScale + 1);
    },
    minLabel: function () {
      return this.question.translations.minLabel.value || this.survey.translations.questions.nps.minLabel.value || this.survey.translations.questions.nps.minLabel.defaultValue;
    },
    maxLabel: function () {
      return this.question.translations.maxLabel.value || this.survey.translations.questions.nps.maxLabel.value || this.survey.translations.questions.nps.maxLabel.defaultValue;
    },
    showMinLabel: function () {
      return this.question.translations.minLabel.active
    },
    showMaxLabel: function () {
      return this.question.translations.maxLabel.active
    },
  },
  methods: {
    onSelect(value) {
      this.activeNum = value;
      setTimeout(() => this.onAnswer(value), process.env.VUE_APP_NEXT_QUESTION_DELAY);
    },
    onKeyUp(e) {
      if (!this.isVisible) return;
      let value = parseInt(e.code.substring(e.code.length - 1));
      if (value >= 0 && value <= this.scoreScale) {
        this.onSelect(value)
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>
