<template>
  <header
      v-if="survey.image_src"
      class="flex justify-center absolute top-3 left-0 w-full"
  >
    <div :class="{'logo-wrapper flex overflow-hidden': !isLoading}">
      <ik-image v-if="!isLoading && survey.image_src"
                :src="survey.image_src | parseImgUrl"
                class="object-contain w-full select-none pointer-events-none"
      />
    </div>
  </header>
</template>

<script>
import {mapGetters} from "vuex";
import {parseImgUrl} from "../helpers/fns";

export default {
  name: "TheHeader",
  filters: {parseImgUrl},
  computed: {
    ...mapGetters(["survey", "isLoading"]),
  }
}
</script>

<style lang="less" scoped>
.logo-wrapper {
  width: 146px;
  height: 44px;
}

@media @v-mobile-xs-break-point {
  .logo-wrapper {
    width: 200px;
    height: 60px;
  }
}
</style>
