<template>
  <div class="title text-center mx-auto text-2xl font-semibold leading-1"
       :style="[titleColor2]"
  >
    <VCollapsedText :title="title"/>
    <slot name="extra"></slot>
  </div>
</template>

<script>
import VCollapsedText from "./VCollapsedText";
import themeMixin from "../mixins/theme.mixin";

export default {
  name: "VTitleNoCard",
  props: {title: String},
  mixins: [themeMixin],
  components: {VCollapsedText}
}
</script>

<style lang="less" scoped>
.title {
  max-width: 376px;
}
</style>
