<template>
  <VCard v-if="thankyouScreen">
    <template #title>
      <div class="space-y-2">
        <div>
          <ik-image v-if="thankyouScreen.image.type === 'custom' && thankyouScreen.image.src"
                    :src="thankyouScreen.image.src | parseImgUrl"
                    style="max-width: 50px; height: 50px;"
                    class="w-full object-cover select-none pointer-events-none"
                    alt="Thank you screen image"
          />
          <a-icon
              v-else-if="thankyouScreen.image.type === 'default' || (survey.hasBranding && thankyouScreen.image.type === 'custom')"
              type="check-circle"
              style="font-size: 50px;"
              :style="titleColor"></a-icon>
        </div>
        <div :style="titleColor">
          {{ thankyouScreen.title ? thankyouScreen.title : defaultMessages.title }}
        </div>
      </div>
    </template>

    <VCollapsedText :title="thankyouScreen.description" :style="textColor" :max-length="200"/>

    <template #button>
      <a v-if="thankyouScreen.button.isActive"
         :href="thankyouScreen.button.link ? thankyouScreen.button.link : landingUrl"
         target="_parent">
        <a-button
            type="primary"
            size="large"
            class="btn-action-primary"
            :style="[buttonBgColor, buttonTextColor]"
        >
          {{ thankyouScreen.button.text ? thankyouScreen.button.text : defaultMessages.button.text }}
        </a-button>
      </a>
      <a v-else-if="survey.hasBranding"
         :href="landingUrl"
         target="_parent">
        <a-button
            type="primary"
            size="large"
            class="btn-action-primary"
            :style="[buttonBgColor, buttonTextColor]"
        >
          {{ defaultMessages.button.text }}
        </a-button>
      </a>
    </template>
  </VCard>
</template>

<script>
import {SurveyBotSuccess} from "@/helpers/customIcon";
import {mapGetters} from "vuex";
import {parseImgUrl} from "../helpers/fns";
import VCard from "./VCard";
import themeMixin from "../mixins/theme.mixin";
import VCollapsedText from "./VCollapsedText";

export default {
  name: "VThankYouScreen",
  filters: {parseImgUrl},
  mixins: [themeMixin],
  components: {VCard, VCollapsedText},
  data() {
    return {
      successIcon: SurveyBotSuccess,
      defaultMessages: {
        title: 'Thank you!',
        button: {
          text: 'CREATE SURVEY FREE',
        },
        image: {
          type: 'default',
        },
      },
    }
  },
  computed: {
    ...mapGetters(["survey", "currentThankyouScreen"]),
    thankyouScreen: function () {
      return this.currentThankyouScreen?.isActive ? this.currentThankyouScreen : this.defaultMessages
    },
    landingUrl: function () {
      return `${process.env.VUE_APP_LANDING_URL}/?utm_source=Survey&utm_campaign=${this.survey._id}&utm_content=Thank+You+Screen`;
    }
  },
}
</script>

<style lang="less" scoped>

</style>
