<template>
  <div>
    <vue-swing
        @throwout="onThrowout"
        @throwoutend="resetCard"
        :config="config"
        ref="vueswing"
        class="relative z-50"
    >
      <VCard :no-padding="true"
             class="cursor-move"
             :class="[cardClass, { 'pointer-events-none': isTextCollapsed }]"
      >
        <div v-if="image_src"
             class="relative flex flex-grow w-full overflow-hidden"
             style="aspect-ratio: 4/3;"
        >

          <!--Blured background-->
          <!--<img :src="image_src | parseImgUrl"-->
          <!--class="absolute w-full h-full select-none pointer-events-none object-cover z-10"-->
          <!--style="filter: blur(5px); transform: scale(1.2);"-->
          <!--@load="onLoadImage"/>-->

          <!--Must be img than ik-image to use @load-->
          <img :src="image_src | parseImgUrl"
               class="w-full select-none pointer-events-none"
               :class="imageSizeClass"
               @load="onLoadImage"
               alt="image"
          />
        </div>

        <div v-if="question.text.trim() !== ''"
             class="flex flex-grow items-center justify-center px-8 pt-4"
             :style="textColor"
        >
          <VCollapsedText
              :title="question.text"
              :max-length="image_src ? 100 : 300"
              @collapse="onCollapseTitle"/>
        </div>

        <template #button>
          <!--negative margin used to reduce main margin-->
          <div class="flex items-center justify-center w-full space-x-4 mb-4 -mt-4">
            <a-button
                type="danger"
                size="large"
                class="hover:opacity-80 transition ease-in-out duration-150 pointer-events-auto"
                style="border-radius: 50px; padding-left: 20px; padding-right: 20px;"
                :style="{
              transform: throwDirection === leftDirection ? 'scale(1.2)' : 'unset',
              filter: throwDirection === rightDirection ? 'grayscale(100%)' : 'unset',
               ...swipeButtonLeftBgColor,
               ...swipeButtonLeftTextColor,
            }"
                @click="swipe(leftDirection)"
            >
              <div class="flex uppercase font-bold text-base">
                {{ leftButton }}
              </div>
            </a-button>
            <a-button
                type="primary"
                size="large"
                class="hover:opacity-80 transition ease-in-out duration-150 pointer-events-auto"
                style="border-radius: 50px; padding-left: 20px; padding-right: 20px;"
                :style="{
              transform: throwDirection === rightDirection ? 'scale(1.2)' : 'unset',
              filter: throwDirection === leftDirection ? 'grayscale(100%)' : 'unset',
             ...swipeButtonRightBgColor,
             ...swipeButtonRightTextColor,
            }"
                @click="swipe(rightDirection)"
            >
              <div class="flex uppercase font-bold text-base">
                {{ rightButton }}
              </div>
            </a-button>
          </div>
        </template>
      </VCard>
    </vue-swing>

    <VHelpText v-if="survey.translations.questions.swipe.swipeDesc.active && !isTextCollapsed">
      <template #content>
        {{
          survey.translations.questions.swipe.swipeDesc.value || survey.translations.questions.swipe.swipeDesc.defaultValue
        }}
      </template>
    </VHelpText>
  </div>
</template>

<script>
import VueSwing from 'vue-swing'
import {questionEventListenerMixin, questionMixin} from "@/mixins/question";
import {parseImgUrl} from "../helpers/fns";
import {capitalize} from "lodash";
import {mapGetters} from "vuex";
import themeMixin from "../mixins/theme.mixin";
import VHelpText from "./VHelpText";
import VCard from "./VCard";
import VCollapsedText from "./VCollapsedText";

export default {
  name: "VSwipeCard",
  props: {isVisible: Boolean},
  components: {VueSwing, VHelpText, VCard, VCollapsedText},
  mixins: [questionMixin, questionEventListenerMixin, themeMixin],
  filters: {
    parseImgUrl,
    capitalize(value) {
      return capitalize(value);
    }
  },
  data() {
    return {
      config: {
        allowedDirections: [
          VueSwing.Direction.LEFT,
          VueSwing.Direction.RIGHT
        ],
        minThrowOutDistance: window.outerWidth + 100,
        maxThrowOutDistance: window.outerWidth + 200,
        maxRotation: 10,
        throwOutConfidence: (xOffset, yOffset, element) => {
          let minXOffset = window.outerWidth > process.env.VUE_APP_MOBILE_WIDTH_BREAKPOINT ? 0.4 : 0.2;
          return Math.abs(xOffset) / element.offsetWidth >= minXOffset ? 1 : 0;
        },
      },
      isVerticalImg: false,
      isBeforeThrowout: false,
      leftDirection: VueSwing.Direction.LEFT,
      rightDirection: VueSwing.Direction.RIGHT,
      leftValue: 'no',
      rightValue: 'yes',
      throwDirection: '',
      isTextCollapsed: false,
    }
  },
  computed: {
    ...mapGetters(["survey"]),
    image_src: function () {
      if (this.question.subtype === 'textOnly' || !this.question.imageSrc) return false;

      return this.question.imageSrc;
    },
    imageSizeClass: function () {
      return (this.question.text.trim() === '' || this.isVerticalImg) ? 'object-contain' : 'object-cover';
    },
    cardClass: function () {
      return this.isBeforeThrowout ? 'transition ease-in duration-100' : ''
    },
    leftButton() {
      return this.question.translations.leftButton.value || this.survey.translations.questions.swipe.leftButton.value || this.survey.translations.questions.swipe.leftButton.defaultValue
    },
    rightButton() {
      return this.question.translations.rightButton.value || this.survey.translations.questions.swipe.rightButton.value || this.survey.translations.questions.swipe.rightButton.defaultValue
    },
  },
  methods: {
    onThrowout({throwDirection}) {
      this.throwDirection = throwDirection;

      let value = (throwDirection === this.leftDirection) ? this.leftValue : this.rightValue;

      // Set animation class before throw out
      this.isBeforeThrowout = true;

      setTimeout(() => this.onAnswer(value), 150);
    },

    swipe(throwDirection) {
      let cards = this.$refs.vueswing.cards;
      let coordX = 100;

      cards[cards.length - 1].throwOut(
          throwDirection === this.leftDirection ? -coordX : coordX,
          1
      );

    },

    onKeyUp(e) {
      if (!this.isVisible) return;

      if (e.code === 'ArrowRight') {
        this.swipe(this.rightDirection);
      } else if (e.code === 'ArrowLeft') {
        this.swipe(this.leftDirection);
      }
    },

    resetCard() {
      this.isBeforeThrowout = false

      let cards = this.$refs.vueswing.cards
      setTimeout(() => {
        cards[cards.length - 1].throwIn(1, 1)
      }, 300)
    },

    onLoadImage(e) {
      if (e && e.target) {
        let img = e.target
        if (img.naturalWidth < img.naturalHeight) {
          this.isVerticalImg = true;
        }
      }
    },

    onCollapseTitle(value) {
      this.isTextCollapsed = value
    },
  }
}
</script>

<style lang="less" scoped>

</style>
