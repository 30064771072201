<template>
  <div>
    <vue-swing
        @throwout="onThrowout"
        :config="config"
        ref="vueswing"
        class="relative z-50"
    >
      <VCard class="cursor-move"
             :bg-image="welcomeScreen.bgImage.isActive ? welcomeScreen.bgImage.src : null"
             :class="[{ 'pointer-events-none': isTextCollapsed }, welcomeScreen.classWrapper]"
      >
        <template #title>
          <span :style="titleColor">{{ welcomeScreen.title || default_messages.title }}</span>
        </template>

        <VCollapsedText
            :title="welcomeScreen.description || default_messages.description"
            :max-length="200"
            :style="textColor"
            @collapse="onCollapseTitle"/>

        <template #button>
          <a-button
              type="primary"
              size="large"
              class="btn-action-primary pointer-events-auto"
              :style="[buttonBgColor, buttonTextColor]"
              @click="onStart"
          >
            {{
              survey.translations.welcomeScreen.buttonStart.value || survey.translations.welcomeScreen.buttonStart.defaultValue
            }}
          </a-button>
        </template>
      </VCard>
    </vue-swing>

    <VHelpText v-if="survey.translations.welcomeScreen.swipeDesc.active && !isTextCollapsed">
      <template #content>
        {{
          survey.translations.welcomeScreen.swipeDesc.value || survey.translations.welcomeScreen.swipeDesc.defaultValue
        }}
      </template>
    </VHelpText>
  </div>
</template>

<script>
import {questionEventListenerMixin} from "@/mixins/question";
import {mapGetters} from "vuex";
import VueSwing from "vue-swing";
import themeMixin from "../mixins/theme.mixin";
import VHelpText from "./VHelpText";
import VCard from "./VCard";
import VCollapsedText from "./VCollapsedText";

export default {
  name: "VWelcomeScreen",
  components: {VueSwing, VHelpText, VCard, VCollapsedText},
  mixins: [questionEventListenerMixin, themeMixin],
  data() {
    return {
      config: {
        allowedDirections: [
          VueSwing.Direction.LEFT,
          VueSwing.Direction.RIGHT
        ],
        minThrowOutDistance: window.outerWidth + 100,
        maxThrowOutDistance: window.outerWidth + 200,
        maxRotation: 10,
        throwOutConfidence: (xOffset, yOffset, element) => {
          let minXOffset = 0.2;
          return Math.abs(xOffset) / element.offsetWidth >= minXOffset ? 1 : 0;
        }
      },
      isBeforeThrowout: false,
      default_messages: {
        title: 'Welcome',
        description: 'Please take this survey and let us know about your experience.',
      },
      isTextCollapsed: false,
    }
  },
  computed: {
    ...mapGetters(["survey"]),
    welcomeScreen: function () {
      const classWrapper = this.isBeforeThrowout ? 'transition ease-in duration-100' : '';
      return {...this.survey.welcomeScreen, classWrapper};
    },
  },
  methods: {
    onStart() {
      this.$emit('onStart');
    },
    onKeyDown(e) {
      if (e.key === 'Enter') {
        this.onStart();
      }
    },
    onThrowout() {
      // Set animation class before throw out
      this.isBeforeThrowout = true;

      setTimeout(() => this.onStart(), 150);
    },
    onCollapseTitle(value) {
      this.isTextCollapsed = value
    },
  }
}
</script>

<style lang="less" scoped>

</style>
