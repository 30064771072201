<template>
  <div>
    <!--Title-->
    <VTitleNoCard :title="question.text" class="mb-8 md:mb-12"/>

    <!--Choices-->
    <div class="flex">
      <div class="m-auto">

        <div class="flex items-center justify-center sm:m-0">
          <a-rate
              v-if="ratingType === 'stars'"
              :allowClear="false"
              class="w-full sm:w-auto justify-between sm:justify-center"
              @change="onSelect"
          ></a-rate>
          <VSmileIcons
              v-else
              :rating-scale="ratingScale"
              :type="ratingType"
              class="w-full sm:w-auto"
              @makeSelect="onSelect"
          />
        </div>

        <div class="flex justify-between w-full mt-3 px-2 space-x-2">
          <div>
          <span v-if="showMinLabel"
                class="text-base" :style="helpTextColor">{{ minLabel }}</span>
          </div>
          <div class="text-right">
          <span v-if="showMaxLabel"
                class="text-base" :style="helpTextColor">{{ maxLabel }}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {Rate} from "ant-design-vue"
import VSmileIcons from "@/components/VSmileIcons";
import questionMixin from "@/mixins/question";
import {mapGetters} from "vuex";
import themeMixin from "../mixins/theme.mixin";
import VTitleNoCard from "./VTitleNoCard";

export default {
  name: "VRating",
  components: {'a-rate': Rate, VSmileIcons, VTitleNoCard},
  mixins: [questionMixin, themeMixin],
  computed: {
    ...mapGetters(["survey"]),
    ratingType: function () {
      return this.question.subtype;
    },
    ratingScale: function () {
      return this.question.options.ratingScale;
    },
    minLabel: function () {
      if (this.ratingType === 'stars') {
        return this.question.translations.minLabel.value || this.survey.translations.questions.likertStars.minLabel.value || this.survey.translations.questions.likertStars.minLabel.defaultValue;
      }
      return this.question.translations.minLabel.value || this.survey.translations.questions.likertSmiles.minLabel.value || this.survey.translations.questions.likertSmiles.minLabel.defaultValue;
    },
    maxLabel: function () {
      if (this.ratingType === 'stars') {
        return this.question.translations.maxLabel.value || this.survey.translations.questions.likertStars.maxLabel.value || this.survey.translations.questions.likertStars.maxLabel.defaultValue;
      }
      return this.question.translations.maxLabel.value || this.survey.translations.questions.likertSmiles.maxLabel.value || this.survey.translations.questions.likertSmiles.maxLabel.defaultValue;
    },
    showMinLabel: function () {
      return this.question.translations.minLabel.active
    },
    showMaxLabel: function () {
      return this.question.translations.maxLabel.active
    },
  },
  methods: {
    onSelect(value) {
      setTimeout(() => this.onAnswer(value), process.env.VUE_APP_NEXT_QUESTION_DELAY);
    }
  }
}
</script>

<style scoped>

</style>
