import Vue from 'vue'
import VueRoter from 'vue-router'
import VSurvey from "@/components/VSurvey";

Vue.use(VueRoter);

const router = new VueRoter({
	routes: [
		{
			path: "/survey/:survey_id",
			component: VSurvey
		},
		{
			path: '*',
			beforeEnter() {
				location.href = process.env.VUE_APP_LANDING_URL
			}
		}

	],
	mode: 'history'
});

router.beforeEach((to, from, next) => {
	if (to.query && to.query.survey_id) {
		const survey_id = to.query.survey_id;
		next({path: `/survey/${survey_id}`})
	}
	next();
});

export default router;
