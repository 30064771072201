import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["survey", "error"]),
    themeSettings: function () {
      const defaultThemeSettings = {
        bgColor: "#F4F6FA",
        titleColor: "#00CCFF",
        titleColor2: "#00CCFF",
        textColor: "#333333",
        helpTextColor: "#9AA5BBB2",
        buttonBgColor: "#39AE61",
        buttonTextColor: "#FFFFFF",
        buttonHintBgColor: "#FF3399",
        buttonHintTextColor: "#FFFFFF",
        swipeButtonLeftBgColor: "#FF3399",
        swipeButtonLeftTextColor: "#FFFFFF",
        swipeButtonRightBgColor: "#39AE61",
        swipeButtonRightTextColor: "#FFFFFF",
        accentColor: "#00CCFF",
      };
      return this.survey?.theme?.settings || this.error?.data?.theme?.settings || defaultThemeSettings;
    },
    titleColor() {
      return {
        color: this.themeSettings.titleColor,
      };
    },
    titleColor2() {
      return {
        color: this.themeSettings.titleColor2,
      };
    },
    textColor() {
      return {
        color: this.themeSettings.textColor,
      };
    },
    helpTextColor() {
      return {
        color: this.themeSettings.helpTextColor,
      };
    },
    bgColor() {
      return {
        backgroundColor: this.themeSettings.bgColor,
      };
    },
    accentColor() {
      return {
        color: this.themeSettings.accentColor,
      };
    },
    buttonBgColor() {
      return {
        background: this.themeSettings.buttonBgColor,
      };
    },
    buttonTextColor() {
      return {
        color: this.themeSettings.buttonTextColor,
      };
    },
    buttonHintBgColor() {
      return {
        background: this.themeSettings.buttonHintBgColor,
      };
    },
    buttonHintTextColor() {
      return {
        color: this.themeSettings.buttonHintTextColor,
      };
    },
    swipeButtonLeftBgColor() {
      return {
        background: this.themeSettings.swipeButtonLeftBgColor,
      };
    },
    swipeButtonLeftTextColor() {
      return {
        color: this.themeSettings.swipeButtonLeftTextColor,
      };
    },
    swipeButtonRightBgColor() {
      return {
        background: this.themeSettings.swipeButtonRightBgColor,
      };
    },
    swipeButtonRightTextColor() {
      return {
        color: this.themeSettings.swipeButtonRightTextColor,
      };
    },
  },
};
