<template>
  <div v-if="showBranding || (survey.tooltip && survey.tooltip.active)"
       class="fixed bottom-0 left-0 flex justify-end items-center py-2 px-5 md:py-5 w-full h-14 space-x-4 pointer-events-auto md:pointer-events-none">
    <a
        v-if="showBranding"
        :href="landingUrl"
        class="branding absolute sm:static left-1/2 transform -translate-x-1/2 sm:transform-none pointer-events-auto bg-white rounded-full px-4 py-2 flex items-center text-character_gray-400 no-underline select-none hover:opacity-80 transition-all duration-100 ease-in"
        :style="{'--accent-color': accentColor.color}"
        target="_blank"
    >
      <span class="flex-none text-sm text-character_gray-400 mr-1">Made in</span>
      <MetasurveyLogoSvg/>
      <span class="text-sm text-character_gray-400 ml-1">Meta<b>Survey</b></span>
    </a>

    <div
        v-if="!isLoading && survey.tooltip && survey.tooltip.active"
        class="info-tooltip pointer-events-auto"
    >
      <a-popover v-model="visibleTooltip" placement="topRight">
        <div slot="content">
          {{ survey.tooltip.value || default_messages.tooltip }}
        </div>
        <div class="flex flex-col items-center cursor-default">
          <a-button
              type="danger"
              icon="info"
              shape="circle"
              class="btn-tooltip"
              style="height: 38px; width: 38px;"
              :style="[buttonHintBgColor, buttonHintTextColor]"
              @click="visibleTooltip = !visibleTooltip"
          ></a-button>
        </div>
      </a-popover>
    </div>
  </div>
</template>

<script>
import {MetasurveyLogoSvg} from "@/helpers/customIcon";
import {mapGetters} from "vuex";
import themeMixin from "../mixins/theme.mixin";

export default {
  name: "TheFooter",
  components: {MetasurveyLogoSvg},
  mixins: [themeMixin],
  data() {
    return {
      visibleTooltip: false,
      default_messages: {
        tooltip: 'We use your feedback to improve your experience.'
      }
    }
  },
  computed: {
    ...mapGetters(["survey", "isLoading", "error"]),
    showBranding() {
      return this.survey.hasBranding || this.error?.data?.hasBranding || this.error?.code?.toString() === '404'
    },
    landingUrl() {
      return `${process.env.VUE_APP_LANDING_URL}/?utm_source=Survey&utm_campaign=${this.survey._id}&utm_content=Footer`;
    }
  }
}
</script>

<style lang="less" scoped>
.branding {
  border: 1px solid var(--accent-color);
}

.info-tooltip {
  &__icon {
    color: @v-color-button-magenta;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
  }
}
</style>
