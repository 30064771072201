<template>
  <VCard v-if="survey.comment.active">
    <template #title>
      <VCollapsedText :title="survey.comment.title || default_messages.title" class="text-xl leading-2 font-normal mb-4" :style="textColor"/>
    </template>

    <a-textarea
        v-model="comment_value"
        :placeholder="survey.comment.placeholder || default_messages.placeholder"
        :autoSize="{minRows: 4, maxRows: 12}"
        :max-length="500"
        class="text-base"
        style="border-radius: 10px;"
    ></a-textarea>

    <template #button>
      <a-button
          type="primary"
          size="large"
          class="btn-action-primary"
          :style="[buttonBgColor, buttonTextColor]"
          @click="onSubmit"
      >
        {{
          survey.translations.comment.buttonSubmit.value || survey.translations.comment.buttonSubmit.defaultValue
        }}
      </a-button>
    </template>
  </VCard>
</template>

<script>
import {mapGetters} from "vuex";
import questionMixin from "../mixins/question";
import themeMixin from "../mixins/theme.mixin";
import VCard from "./VCard";
import VCollapsedText from "./VCollapsedText";

export default {
  name: "VComment",
  props: {
    active: {type: Boolean},
    placeholder: {type: String, default: ''}
  },
  components: {VCard, VCollapsedText},
  mixins: [questionMixin, themeMixin],
  data() {
    return {
      comment_value: '',
      default_messages: {
        title: 'Tell us what you think',
        placeholder: 'Your feedback here',
        btnLabel: 'Send',
      },
    }
  },
  computed: {
    ...mapGetters(["survey"]),
  },
  methods: {
    onSubmit() {
      this.makeFinishSurvey(this.comment_value);
    },
    makeFinishSurvey(comment_value = '') {
      this.$emit('onFinishSurvey', comment_value);
    }
  }
}
</script>

<style lang="less" scoped>
.comment {
  height: 388px;
  box-shadow: 5px 4px 15px rgba(0, 0, 0, 0.15);
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  background-color: @v-color-base-light;

  &__wrapper {
    width: 100%;
    max-width: 350px;
  }

  & > textarea {
    height: 224px;
    line-height: 1;
    font-size: @v-font-size-base;
    padding: 12px 20px;
  }
}

@media @v-mobile-break-point {
  .comment {
    height: @v-card-height;

    &__wrapper {
      width: @v-card-width;
    }
  }
}
</style>
