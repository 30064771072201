<template>
  <div class="flex">
    <div class="m-auto flex space-x-2 sm:space-x-7">
      <a-button
          v-for="value in smiles"
          :key="value"
          type="default"
          size="large"
          shape="circle"
          class="btn-smile transform hover:-translate-y-1 transition-all ease-out duration-300"
          :class="{
            'selected': activeNum && activeNum === value && type === 'smilesGray',
            'not-selected': activeNum && activeNum !== value,
          }"
          @click="makeSelect(value)"
      >
        <a-icon
            :component="smileTypes[type][value]"
            style="font-size: 52px"
            ref="iconRef"
        ></a-icon>
      </a-button>
    </div>
  </div>
</template>

<script>
import {
  SmileColorfulNeutral,
  SmileColorfulPositive,
  SmileColorfulSad,
  SmileColorfulSuspicious,
  SmileColorfulThinking,
  SmileGrayNeutral,
  SmileGrayPositive,
  SmileGraySad,
  SmileGraySuspicious,
  SmileGrayThinking
} from "@/helpers/customIcon";
import themeMixin from "../mixins/theme.mixin";

export default {
  name: "VSmileIcons",
  props: ["ratingScale", "type"],
  mixins: [themeMixin],
  data() {
    return {
      smileTypes: {
        smilesColorful: {
          1: SmileColorfulSad,
          2: SmileColorfulThinking,
          3: SmileColorfulNeutral,
          4: SmileColorfulSuspicious,
          5: SmileColorfulPositive,
        },
        smilesGray: {
          1: SmileGraySad,
          2: SmileGrayThinking,
          3: SmileGrayNeutral,
          4: SmileGraySuspicious,
          5: SmileGrayPositive,
        }
      },
      activeNum: null,
    }
  },
  computed: {
    smiles: function () {
      let values;
      switch (this.ratingScale) {
        case(2):
          values = [1, 5]
          break;
        case(3):
          values = [1, 3, 5]
          break;
        default:
          values = [1, 2, 3, 4, 5]
      }
      return values;
    }
  },
  methods: {
    makeSelect(value) {
      this.activeNum = value;
      const iconRef = this.$refs.iconRef[value - 1];

      if (iconRef && this.type === 'smilesGray') {
        const svgElement = iconRef.$el.querySelector('svg');

        if (svgElement) {
          const pathElement = svgElement.querySelector('path');
          if (pathElement) {
            pathElement.setAttribute('fill', this.accentColor.color);
          }
        }
      }

      this.$emit('makeSelect', value);
    },
  }
}
</script>

<style scoped>

</style>
