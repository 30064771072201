import {startsWith} from "lodash/string";

export function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function parseImgUrl(url) {

  if (!startsWith(url, process.env.VUE_APP_API_URL) || process.env.NODE_ENV === "dev") return url

  let arSplittedUrl = url.split("/")
  return process.env.VUE_APP_IMAGEKIT_URL_ENDPOINT + arSplittedUrl[arSplittedUrl.length - 1] + "?tr=w-700"

}

export function getUrlParam(param) {
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
  } catch (error) {
    // Handle any potential errors (e.g., cross-origin issues)
    console.error(error);
    return null;
  }
}

export function listUrlParamsStr() {
  try {
    let paramsList = [];
    const urlParams = new URLSearchParams(window.location.search);

    for (const param of urlParams.entries()) {
      let itemStr = `${param[0]}=${param[1]}`;
      paramsList.push(itemStr);
    }

    return paramsList.join(',');
  } catch (error) {
    // Handle any potential errors (e.g., cross-origin issues)
    console.error(error);
    return '';
  }
}

export function setBackground(color) {
  const body = document.getElementsByTagName('body')[0]

  body.style.backgroundColor = color

  setLightOrDarkTheme(color)
}

// https://awik.io/determine-color-bright-dark-using-javascript/
function setLightOrDarkTheme(color) {
  var r, g, b, hsp;
  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  if (hsp <= 186) {
    document.getElementsByTagName('html')[0].classList.add('dark')
  }
}

export function surveyPostMessage(type, data) {
  window.top.postMessage({type, data}, '*');
}
