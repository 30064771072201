import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import store from "@/store"
import './assets/less/index.less'
import {Icon, Popover, Button, Tooltip, Input, Select, Form, message, Progress} from "ant-design-vue"
import ImageKit from "imagekitio-vue"
import * as Sentry from "@sentry/vue"

Vue.use(Icon);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(Input);
Vue.use(Form);
Vue.use(Progress);
Vue.use(Select);
Vue.use(ImageKit, {urlEndpoint: process.env.VUE_APP_IMAGEKIT_URL_ENDPOINT})
Vue.prototype.$message = message;
Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [process.env.VUE_APP_PUBLIC_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});



new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
